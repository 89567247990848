
.action
{
    cursor: pointer;
}
.action_button
{
    background-color: white;
    --cui-btn-color:'none';
    outline: none;
    border: none;
    color: black;
    --cui-btn-active-bg:none;
}
.action_button:hover
{
    background-color: white;
    --cui-btn-color:'none';
    outline: none;
    border: none;
    color: black;
}
.popover-arrow
{
    display: none !important;
}
.viewdetails
{
    display: grid;
    grid-row-gap: 8px;
    padding: 16px 20px;
}
.view
{
    font-size: 14px;
    color: #00CFE8!important;
    display: flex;
    align-items: center;
    grid-column-gap: 4px;
}
.delete
{
    font-size: 14px;
    color: red;
    display: flex;
    align-items: center;
    grid-column-gap: 4px;
}
.edit{
    font-size: 14px;
    color: #000000;
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
    font-weight: 500;
}
.modalbody{
    border-bottom: var(--cui-modal-header-border-width) solid var(--cui-modal-header-border-color);
    padding: 6px 20px;
}
.popover-body
{
    padding: 0px;
}
.accountitems
{
    background-color: transparent !important;
    color: #000000 !important;
    border: none !important;
}
.react-loading-skeleton
{
    --base-color: #c5c2c2 !important;
    --highlight-color: #dadada !important;
}
.store_data
{
   display: grid;
   align-items: center;
   grid-template-columns: 0.75fr 1fr;
   grid-column-gap: 10px;
   
}
.text-medium-emphasis
{
    margin: 4px 0px 12px !important;
}
.container-header
{
    display: flex;
    grid-column-gap: 6px;
    align-items: center;
}
.store
{
    width:200px;
}
.explore_image_recommend
{
    width:40px;
    height:40px;
}
.explore_image_recommend img
{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.sidebar
{
    --cui-sidebar-bg: rgb(36, 39, 69);
   
}
.sidebar-brand
{
    background:black;
}
.sidebar-toggler
{
    background: black;
}

.button
{
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.cbutton{
  padding-inline: 10px;
  position: relative;
  top: -10px;
}
.primary_title
{
    font-size: 18px;
}
.head_title
{
    font-size: 22px;
}
.entry
{
    padding-bottom: 1rem !important;
}

.form-label
{
    color: red;
    position: relative;
    margin-block: 0px ;
    top:-10px;
}
.forget-password
{
    position: relative;
    top:-8px;
}
.create
{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
}
.creation
{
    display: grid;
    grid-row-gap: 0px;
}
.verify_restart
{
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 10px;
    position: relative;
    top:-16px;
}


.verify_otp
{
    color: #321fdb;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding-top: 5px;

}

.verify_otpcode
{
    color: #321fdb;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    padding-top: 5px;
}

.reset-password
{
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    position: absolute;
    height: auto;
    width: 100%;
}
.toastshow
{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    width: 100%;
    z-index: 9999;
}

.list-item
{
    list-style-type: none;
}

.form-switch .form-check-input
{
    --cui-form-switch-bg:url(
        "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>") !important;
}

table{
    font-size: 12px !important;
}

@media (max-width:768px)
{
    .primary_title
    {
        font-size: 16px;
    }
}